import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 自定义语言包
import vc_zh_CN from './lang/zh-CN' // 简体中文
import vc_zh_TW from './lang/zh-TW' // 繁体中文
// import vc_zh_UG from './lang/zh-UG' // 维吾尔语
import vc_en_US from './lang/en-US' // 英文
import vc_tr_TR from './lang/tr-TR' // 土耳其语
import vc_es_ES from './lang/es-ES' // 西班牙语
import vc_ja_JP from './lang/ja-JP' // 日语
import vc_ru_RU from './lang/ru-RU' // 俄语
import vc_fr_FR from './lang/fr-FR' // 法语
import vc_de_DE from './lang/de-DE' // 德语
import vc_pt_BR from './lang/pt-BR' // 巴西葡萄牙语
import vc_pt_PT from './lang/pt-PT' // 葡萄牙语
import vc_ko_KR from './lang/ko-KR' // 韩语
import vc_vi_VN from './lang/vi-VN' // 越南语
import vc_sv_SE from './lang/sv-SE' // 瑞典语
import vc_id_ID from './lang/id-ID' // 印尼语
import vc_uk_UA from './lang/uk-UA' // 乌克兰语
import vc_it_IT from './lang/it-IT' // 意大利语
import vc_th_TH from './lang/th-TH' // 泰语
import vc_hi_IN from './lang/hi-IN' // 印地语
import vc_fa_IR from './lang/fa-IR' // 波斯语
import vc_ro_RO from './lang/ro-RO' // 罗马尼亚语
import vc_el_GR from './lang/el-GR' // 希腊语
import vc_nl_NL from './lang/nl-NL' // 荷兰语
import vc_cs_CZ from './lang/cs-CZ' // 捷克语
import vc_mn_MN from './lang/mn-MN' // 蒙古语
// import vc_mn_TR from './lang/mn-TR' // 传统蒙古语
import vc_fi_FI from './lang/fi-FI' // 芬兰语
// import vc_ar_SA from './lang/ar-SA' // 阿拉伯语
import vc_ar_EG from './lang/ar-EG' // 阿拉伯语-埃及
import vc_da_DK from './lang/da-DK' // 丹麦语
import vc_pl_PL from './lang/pl-PL' // 波兰语
import vc_nb_NO from './lang/nb-NO' // 挪威语

// view-design 语言包
import ui_zh_CN from 'ant-design-vue/es/locale/zh_CN' // 简体中文
import ui_zh_TW from 'ant-design-vue/es/locale/zh_TW' // 繁体中文
// import ui_zh_UG from 'ant-design-vue/es/locale/zh_UG' // 维吾尔语
import ui_en_US from 'ant-design-vue/es/locale/en_US' // 英文
import ui_tr_TR from 'ant-design-vue/es/locale/tr_TR' // 土耳其语
import ui_es_ES from 'ant-design-vue/es/locale/es_ES' // 西班牙语
import ui_ja_JP from 'ant-design-vue/es/locale/ja_JP' // 日语
import ui_ru_RU from 'ant-design-vue/es/locale/ru_RU' // 俄语
import ui_fr_FR from 'ant-design-vue/es/locale/fr_FR' // 法语
import ui_de_DE from 'ant-design-vue/es/locale/de_DE' // 德语
import ui_pt_BR from 'ant-design-vue/es/locale/pt_BR' // 巴西葡萄牙语
import ui_pt_PT from 'ant-design-vue/es/locale/pt_PT' // 葡萄牙语
import ui_ko_KR from 'ant-design-vue/es/locale/ko_KR' // 韩语
import ui_vi_VN from 'ant-design-vue/es/locale/vi_VN' // 越南语
import ui_sv_SE from 'ant-design-vue/es/locale/sv_SE' // 瑞典语
import ui_id_ID from 'ant-design-vue/es/locale/id_ID' // 印尼语
import ui_uk_UA from 'ant-design-vue/es/locale/uk_UA' // 乌克兰语
import ui_it_IT from 'ant-design-vue/es/locale/it_IT' // 意大利语
import ui_th_TH from 'ant-design-vue/es/locale/th_TH' // 泰语
import ui_hi_IN from 'ant-design-vue/es/locale/hi_IN' // 印地语
import ui_fa_IR from 'ant-design-vue/es/locale/fa_IR' // 波斯语
import ui_ro_RO from 'ant-design-vue/es/locale/ro_RO' // 罗马尼亚语
import ui_el_GR from 'ant-design-vue/es/locale/el_GR' // 希腊语
import ui_nl_NL from 'ant-design-vue/es/locale/nl_NL' // 荷兰语
import ui_cs_CZ from 'ant-design-vue/es/locale/cs_CZ' // 捷克语
import ui_mn_MN from 'ant-design-vue/es/locale/mn_MN' // 蒙古语
// import ui_mn_TR from 'ant-design-vue/es/locale/mn_TR' // 传统蒙古语
import ui_fi_FI from 'ant-design-vue/es/locale/fi_FI' // 芬兰语
// import ui_ar_SA from 'ant-design-vue/es/locale/ar_SA' // 阿拉伯语
import ui_ar_EG from 'ant-design-vue/es/locale/ar_EG' // 阿拉伯语-埃及
import ui_da_DK from 'ant-design-vue/es/locale/da_DK' // 丹麦语
import ui_pl_PL from 'ant-design-vue/es/locale/pl_PL' // 波兰语
import ui_nb_NO from 'ant-design-vue/es/locale/nb_NO' // 挪威语

Vue.use(VueI18n)
Vue.locale = () => {}

const i18n = new VueI18n({
  locale: sessionStorage.getItem('language') || navigator.language,
  fallbackLocale: 'en_US',
  messages: {
    'zh-CN': Object.assign(vc_zh_CN, ui_zh_CN),  // 简体中文
    'zh-TW': Object.assign(vc_zh_TW, ui_zh_TW),  // 繁体中文
    // 'zh-UG': Object.assign(vc_zh_UG, ui_zh_UG),  // 维吾尔语
    'en-US': Object.assign(vc_en_US, ui_en_US),  // 英文
    'tr-TR': Object.assign(vc_tr_TR, ui_tr_TR),  // 土耳其语
    'es-ES': Object.assign(vc_es_ES, ui_es_ES),  // 西班牙语
    'ja-JP': Object.assign(vc_ja_JP, ui_ja_JP),  // 日语
    'ru-RU': Object.assign(vc_ru_RU, ui_ru_RU),  // 俄语
    'fr-FR': Object.assign(vc_fr_FR, ui_fr_FR),  // 法语
    'de-DE': Object.assign(vc_de_DE, ui_de_DE),  // 德语
    'pt-BR': Object.assign(vc_pt_BR, ui_pt_BR),  // 巴西葡萄牙语
    'pt-PT': Object.assign(vc_pt_PT, ui_pt_PT),  // 葡萄牙语
    'ko-KR': Object.assign(vc_ko_KR, ui_ko_KR),  // 韩语
    'vi-VN': Object.assign(vc_vi_VN, ui_vi_VN),  // 越南语
    'sv-SE': Object.assign(vc_sv_SE, ui_sv_SE),  // 瑞典语
    'id-ID': Object.assign(vc_id_ID, ui_id_ID),  // 印尼语
    'uk-UA': Object.assign(vc_uk_UA, ui_uk_UA),  // 乌克兰语
    'it-IT': Object.assign(vc_it_IT, ui_it_IT),  // 意大利语
    'th-TH': Object.assign(vc_th_TH, ui_th_TH),  // 泰语
    'hi-IN': Object.assign(vc_hi_IN, ui_hi_IN),  // 印地语
    'fa-IR': Object.assign(vc_fa_IR, ui_fa_IR),  // 波斯语
    'ro-RO': Object.assign(vc_ro_RO, ui_ro_RO),  // 罗马尼亚语
    'el-GR': Object.assign(vc_el_GR, ui_el_GR),  // 希腊语
    'nl-NL': Object.assign(vc_nl_NL, ui_nl_NL),  // 荷兰语
    'cs-CZ': Object.assign(vc_cs_CZ, ui_cs_CZ),  // 捷克语
    'mn-MN': Object.assign(vc_mn_MN, ui_mn_MN),  // 蒙古语
    // 'mn-TR': Object.assign(vc_mn_TR, ui_mn_TR),  // 传统蒙古语
    'fi-FI': Object.assign(vc_fi_FI, ui_fi_FI),  // 芬兰语
    // 'ar-SA': Object.assign(vc_ar_SA, ui_ar_SA),  // 阿拉伯语
    'ar-EG': Object.assign(vc_ar_EG, ui_ar_EG),  // 阿拉伯语-埃及
    'da-DK': Object.assign(vc_da_DK, ui_da_DK),  // 丹麦语
    'pl-PL': Object.assign(vc_pl_PL, ui_pl_PL),  // 波兰语
    'nb-NO': Object.assign(vc_nb_NO, ui_nb_NO),  // 挪威语
  },
  silentTranslationWarn: true
})

// i18n
// "wish_you_good_luck": "Wishing You a Year of Prosperity. <br /> Good Fortune Starts Here!"
// p(v-html='$t("message.wish_you_good_luck")')

Vue.prototype._i18n = i18n;

export function i18nRender(key) {
  return i18n.t(`${key}`)
}

export default i18n