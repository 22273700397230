import router from '@/router'
import store from '@/store'
// import storage from 'store'

// progress bar
import NProgress from 'nprogress'
import '@/components/NProgress/nprogress.less'

import notification from 'ant-design-vue/es/notification'

import { setDocumentTitle, domTitle } from '@/utils/domUtil'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/i18n'

// NProgress Configuration
NProgress.configure({ showSpinner: false })

const NoNeedAuth = [ 'Sign', 'Register', 'Lost Password', 'Not Found' ]
const SignRoutePath = '/sign'
const DefaultRoutePath = '/console'

let num = 0;

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  
  const { name, meta, path, fullPath } = to;
  const { title } = meta;

  // const Signed = storage.get(ACCESS_TOKEN)
  const Signed = store.getters['account/is_sign']

  if (!Signed) {
    if (NoNeedAuth.includes(name)) return next()
    return next({ path: SignRoutePath, query: { redirect: fullPath } })
  }
  if (Signed && path === SignRoutePath) return next({ path: DefaultRoutePath })
    
  if (!store.getters.addRouters.length) {
    try {
      await store.dispatch('GenerateRoutes', { roles: [] })
  
      // console.log('%c new route parsed. -> ', "background: #222; color: #bada55", store.getters.addRouters)
  
      // 根据 roles 权限生成可访问的路由表
      // 动态添加可访问路由表
      // VueRouter@3.5.0+ New API
  
      await Promise.all(
        store.getters.addRouters.map(r => router.addRoute(r))
      )
  
      // const AllRoutes = router.getRoutes();
      // AllRoutes.map(route => {
      //   console.log(`%c all routes ==>`, "background: #bada55; color: #222", route);
      // })
  
  
      if (num <= 1) {
        ++num;
  
        // 请求带有 redirect 重定向时，登录自动重定向到该地址
        const redirect = decodeURIComponent(from.query.redirect || path)
        if (path === redirect) return next({ ...to, replace: true })
        return next({ path: redirect })
      }
    } catch(err) {
      console.log('GenerateRoutes Err.', err)
  
      notification.error({
        message: '错误',
        description: '请求用户信息失败，请重试'
      })
  
      // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
      // await store.dispatch('Logout')
      // return next({ path: SignRoutePath, query: { redirect: fullPath } })
    }
  }
  
  /*
  if (store.getters.roles.length === 0) {
    store.dispatch('GetInfo').then(res => {
      const roles = res.result && res.result.role
      // generate dynamic router
      store.dispatch('GenerateRoutes', { roles }).then(() => {
        // 根据roles权限生成可访问的路由表
        // 动态添加可访问路由表
        // VueRouter@3.5.0+ New API
        store.getters.addRouters.forEach(r => {
          router.addRoute(r)
        })
        // 请求带有 redirect 重定向时，登录自动重定向到该地址
        const redirect = decodeURIComponent(from.query.redirect || to.path)
        if (to.path === redirect) {
          // set the replace: true so the navigation will not leave a history record
          next({ ...to, replace: true })
        } else {
          // 跳转到目的路由
          next({ path: redirect })
        }
      })
    }).catch(() => {
      notification.error({
        message: '错误',
        description: '请求用户信息失败，请重试'
      })
      // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
      store.dispatch('Logout').then(() => {
        next({ path: SignRoutePath, query: { redirect: to.fullPath } })
      })
    })
  }
  */
 
  if (title) setDocumentTitle(`${i18nRender(title)} - ${domTitle}`) 

  // console.log('--- 初始化路由 ---')
  
  next();
})

router.afterEach(() => {
  NProgress.done()
})

router.onReady(() => {
  // connect socket
  // const Signed = store.getters['account/is_sign'];
  // if (!Signed || socket.connected) return;
  
  // const token = sessionStorage.getItem('token');
  // socket.io.opts.query = { token };
  // socket.connect();
})

router.onError(err => {
  console.log('router error', err)
})
