module.exports = {
  brand: 'VC 대화식 데이터 구름',
  console: '콘솔',
  message: {
    title: '스포츠 브랜드'
  },
  placeholder: {
    enter: '당신이 좋아하는 브랜드를 입력하십시오'
  },
  brands: {
    nike: '나이키',
    adi: '아디다스',
    nb: '새로운 진부한 어조',
    ln: '이녕'
  }
}