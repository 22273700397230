import Vue from 'vue'
import App from './App.vue'

import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import ui from '@/ui'

import '@/permission' // permission control

// import Utils from '@/utils'
import Axios from '@/plugins/axios'

import 'babel-polyfill'
import 'es6-promise/auto'

// Vue.use(Utils)
Vue.use(Axios)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  ui,
  render: h => h(App),
}).$mount('#app')
