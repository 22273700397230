'strict'

import Vue from 'vue'

import { Axios as axios } from '@/plugins/axios'

export const SIGNIN = 'SIGNIN'
export const SIGNOUT = 'SIGNOUT'

export default {
  namespaced: true,
  state: JSON.parse(sessionStorage.getItem('user')) || {
    id: '',
    auths: {}
  },
  getters: {
    is_sign: state => Boolean(state.id),
  },
  mutations: {
    SIGNIN(state, user) {
      Object.assign(state, user);
      sessionStorage.setItem('user', JSON.stringify(state));
    },
    SIGNOUT(state) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      Object.keys(state).forEach(k => Vue.delete(state, k));
      this.state.account = { id: '', auths: {} };
    }
  },
  actions: {
    signin({ commit }, params) {
      // console.log(params)

      const { type, account, email, mobile, password } = params;
      let form;
      switch(type) {
        case 'account':
          form = { account, password };
          break;
        case 'email':
          form = { email, password };
          break;
        case 'mobile':
          form = { mobile, password };
          break;
        default:
          //- throw new Error('类型错误');
      }
      
      return new Promise((resolve, reject) => {
        axios.post(`signin?type=${type}`, form).then(res => {
          commit('SIGNIN', res);
          resolve(res);
        }).catch(err => reject(err));
      });
    },

    signout({ commit }) {
      commit('SIGNOUT');
    },

    // lost_password({ commit }, params) {
    // },
  }
}