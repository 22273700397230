import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'

const modules = {}
const files = require.context('./modules/', false, /\.*\.js$/)
files.keys().map(key => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})


// import app from './modules/app'
// import user from './modules/user'

// default router permission control
// import permission from './modules/permission'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'


Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules,
  state: {},
  mutations: {
    // socket_connect(state, res) {
    //   state.connected = res;
    //   state.disconnected = !res;
    // },
  },
  actions: {},
  getters
})

// State      | vue -> data
// Getter     | vue -> computed
// Mutation   | vue -> methods
// Action     | interface
// Module