'strict'

export default [
  {
    key: 'console',
    title: '控制台',
    url: '/console',
    meta: { icon: 'appstore' }
  },
  {
    key: 'page',
    title: '页面管理',
    meta: { icon: 'file' },
    children: [
      {
        key: 'management-page',
        title: '页面管理',
        url: '/page',
      },
      {
        key: 'add-page',
        title: '新增页面',
        url: '/page/add',
      },
      {
        key: 'management-history',
        title: '历程管理',
        url: '/history',
      }
    ]
  },
  {
    key: 'post',
    title: '动态管理',
    meta: { icon: 'file-text' },
    children: [
      {
        key: 'management-post',
        title: '管理动态',
        url: '/post',
      },
      {
        key: 'add-post',
        title: '新增动态',
        url: '/post/add',
      },
      {
        key: 'management-post-category',
        title: '动态分类',
        url: '/post-category',
      }
    ]
  },
  {
    key: 'product',
    title: '产品管理',
    meta: { icon: 'shopping-cart' },
    children: [
      {
        key: 'management-product',
        title: '管理产品',
        url: '/product',
      },
      {
        key: 'add-product',
        title: '新增产品',
        url: '/product/add',
      },
      {
        key: 'management-product-category',
        title: '产品分类',
        url: '/product-category',
      }
    ]
  },
  {
    key: 'case',
    title: '案例管理',
    meta: { icon: 'file-image' },
    children: [
      {
        key: 'management-case',
        title: '案例管理',
        url: '/case',
      },
      {
        key: 'add-case',
        title: '新增案例',
        url: '/case/add',
      }
    ]
  },
  {
    key: 'management-user',
    title: '用户管理',
    url: '/user',
    meta: { icon: 'user' }
  },
  // {
  //   key: 'management-attachment',
  //   title: '附件管理',
  //   url: '/attachment',
  //   meta: { icon: 'paper-clip' }
  // },
  // -
  // {
  //   key: 'tiia',
  //   title: '图片库管理',
  //   meta: { icon: 'shopping-cart' },
  //   children: [
  //     {
  //       key: 'management-tiia',
  //       title: '图片库列表',
  //       url: '/tiia',
  //     },
  //     {
  //       key: 'add-tiia',
  //       title: '新增图片库',
  //       url: '/tiia/add',
  //     }
  //   ]
  // },
  // -
  // {
  //   key: 'setting',
  //   title: '设置',
  //   url: '/setting',
  //   meta: { icon: 'setting' }
  // },
]