'strict'

export default {
  debug: false,
  development: {
    API: 'http://localhost:7001/api/',
    // SocketAPI: 'http://socket',
    // WebsocketAPI: 'ws://server'
  },
  production: {
    API: 'https://gdstnewwave.com/api/',
    // SocketAPI: 'http://socket',
    // WebsocketAPI: 'ws://server'
  }
}