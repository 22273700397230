module.exports = {
  brand: 'VC интерактивное облако данных',
  console: 'соболезнование',
  message: {
    title: 'спортивный бренд'
  },
  placeholder: {
    enter: 'Введите ваш любимый бренд'
  },
  brands: {
    nike: 'найк',
    adi: 'адидас',
    nb: 'Новая клише',
    ln: 'Ли Нин'
  }
}