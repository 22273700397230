import axios from 'axios'
import promise from 'es6-promise'
// import querystring from 'querystring'
import config from '@/config'

import store from '@/store'
import router from '@/router'


promise.polyfill();

axios.defaults.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.baseURL = config[process.env.NODE_ENV].API;
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
  const token = sessionStorage.getItem('token');
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  return config;
}, error => {
  return Promise.reject(error);
})

axios.interceptors.response.use(res => {
  const token = res.headers['authorization'];
  if (token) sessionStorage.setItem('token', token);
  
  if (res.status !== 200 && res.status !== 201 && res.status !== 204) return Promise.reject(res);
  return res.data;
}, async error => {
  const { response } = error;
  if (response.status === 401) {
    await store.dispatch('account/signout');
    router.replace({ name: 'Sign', query: { redirect: router.currentRoute.fullPath } });
  }
  return Promise.reject(response.data ? response.data : error);
})

// export const Axios = axios.create({
//   paramsSerializer: params => querystring.stringify(params)
// })

export const Axios = axios;

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$http', { value: axios });
  }
}