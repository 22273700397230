<template lang="pug">
a-config-provider(:locale="locale")
  router-view
</template>

<script>
import zh_CN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'App',
  data() {
    return {
      locale: zh_CN,
    };
  },
  beforeCreate() {
    //- document.getElementsByTagName('body')[0].className = 'windows desktop'
  },
  beforeDestroy() {
    //- document.body.removeAttribute("class", "windows")
  }
}
</script>