'strict'

export default {
  // 品牌
  brand: {
    text: '汕头新潮塑胶包装有限公司',
    img: '', // require('../assets/logo.svg'),
  },
  brand_short: {
    text: '新潮塑胶'
  },
  
  // 布局
  layout: {
    classes: [],
    header: {
      fixed: true
    },
    menu: {
      behind: false,
      collapsed: false,
    },
    footer: {}
  },

  // 额外
  copyright: 2000,
  powered: 'Nodejs, Vue, Antd'
}