
export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/layout/Layout' /* webpackChunkName: 'layout' */),
    redirect: '/console',
    children: [
      {
        path: 'console',
        name: 'Console',
        meta: { icon: 'ios-stats', title: '控制台' },
        component: () => import('@/views/MainView' /* webpackChunkName: 'main' */)
      },
      // ---
      {
        path: 'page/add',
        name: 'Page Add',
        meta: { title: '添加页面' },
        component: () => import('@/views/page/PageAdd' /* webpackChunkName: 'page-add' */)
      },
      {
        path: 'page/:id',
        name: 'Page Edit',
        meta: { title: '编辑页面' },
        component: () => import('@/views/page/PageEdit' /* webpackChunkName: 'page-edit' */)
      },
      {
        path: 'page',
        name: 'Page Management',
        meta: { title: '页面管理' },
        component: () => import('@/views/page/PageManagement' /* webpackChunkName: 'page' */)
      },
      // -
      {
        path: 'case/add',
        name: 'Case Add',
        meta: { title: '添加案例' },
        component: () => import('@/views/case/CaseAdd' /* webpackChunkName: 'case-add' */)
      },
      {
        path: 'case/:id',
        name: 'Case Edit',
        meta: { title: '编辑案例' },
        component: () => import('@/views/case/CaseEdit' /* webpackChunkName: 'case-edit' */)
      },
      {
        path: 'case',
        name: 'Case Management',
        meta: { title: '案例管理' },
        component: () => import('@/views/case/CaseManagement' /* webpackChunkName: 'case' */)
      },
      // -
      {
        path: 'history',
        name: 'History Management',
        meta: { title: '历程管理' },
        component: () => import('@/views/history/HistoryManagement' /* webpackChunkName: 'history' */)
      },
      // -
      {
        path: 'post/add',
        name: 'Post Add',
        meta: { title: '新增动态' },
        component: () => import('@/views/post/PostAdd' /* webpackChunkName: 'post-add' */)
      },
      {
        path: 'post/:id',
        name: 'Post Edit',
        meta: { title: '编辑动态' },
        component: () => import('@/views/post/PostEdit' /* webpackChunkName: 'post-edit' */)
      },
      {
        path: 'post',
        name: 'Post Management',
        meta: { title: '动态管理' },
        component: () => import('@/views/post/PostManagement' /* webpackChunkName: 'post' */)
      },
      {
        path: 'post-category',
        name: 'Post Category Management',
        meta: { title: '动态分类管理' },
        component: () => import('@/views/post/PostCategoryManagement' /* webpackChunkName: 'post-category' */)
      },
      // -
      {
        path: 'user',
        name: 'User Management',
        meta: { title: '用户管理' },
        component: () => import('@/views/user/UserManagement' /* webpackChunkName: 'user' */)
      },
      {
        path: 'attachment',
        name: 'Attachment Management',
        meta: { title: '附件管理' },
        component: () => import('@/views/attachment/AttachmentManagement' /* webpackChunkName: 'attachment' */)
      },
      {
        path: 'setting',
        name: 'Setting',
        meta: { title: '基础设置' },
        component: () => import('@/views/admin/setting/BasicView' /* webpackChunkName: 'setting-basic' */)
      },
      
      // 产品
      {
        path: 'product/add',
        name: 'Product Add',
        meta: { title: '新增产品' },
        component: () => import('@/views/product/ProductAdd' /* webpackChunkName: 'product-add' */)
      },
      {
        path: 'product/:id',
        name: 'Product Edit',
        meta: { title: '编辑产品' },
        component: () => import('@/views/product/ProductEdit' /* webpackChunkName: 'product-edit' */)
      },
      {
        path: 'product',
        name: 'Product Management',
        meta: { title: '产品管理' },
        component: () => import('@/views/product/ProductManagement' /* webpackChunkName: 'product' */)
      },
      {
        path: 'product-category',
        name: 'Product Category Management',
        meta: { title: '产品分类管理' },
        component: () => import('@/views/product/ProductCategoryManagement' /* webpackChunkName: 'product-category' */)
      },
      // - 图片库
      {
        path: 'tiia/add',
        name: 'Tiia Add',
        meta: { title: '新增图片库' },
        component: () => import('@/views/tiia/TiiaAdd' /* webpackChunkName: 'tiia-add' */)
      },
      {
        path: 'tiia/:id',
        name: 'Tiia Edit',
        meta: { title: '编辑图片库' },
        component: () => import('@/views/tiia/TiiaEdit' /* webpackChunkName: 'tiia-edit' */)
      },
      {
        path: 'tiia',
        name: 'Tiia Management',
        meta: { title: '图片库管理' },
        component: () => import('@/views/tiia/TiiaManagement' /* webpackChunkName: 'tiia' */)
      },
      // // 用户
      // {
      //   path: 'user-group/add',
      //   name: 'User Group Add',
      //   meta: { title: '添加用户组' },
      //   component: () => import('@/views/user/UserManagement.vue' /* webpackChunkName: 'user' */)
      // },
      // {
      //   path: 'user-group/:id',
      //   name: 'User Group Edit',
      //   meta: { title: '编辑用户组' },
      //   component: () => import('@/views/user/UserManagement.vue' /* webpackChunkName: 'user' */)
      // },
      // {
      //   path: 'user-group-detail/:id',
      //   name: 'User Group Detail',
      //   meta: { title: '用户组详情' },
      //   component: () => import('@/views/user/UserGroup.vue' /* webpackChunkName: 'user-group' */)
      // },
      // {
      //   path: 'user/add',
      //   name: 'User Add',
      //   meta: { title: '添加用户' },
      //   component: () => import('@/views/user/UserManagement.vue' /* webpackChunkName: 'user' */)
      // },
      // {
      //   path: 'user',
      //   name: 'User Management',
      //   meta: { title: '用户管理' },
      //   component: () => import('@/views/user/UserManagement.vue' /* webpackChunkName: 'user' */)
      // },
    ]
  },

  // :::::::: 用户中心 ::::::::
  {
    path: '/user',
    redirect: 'user/profile',
    component: () => import('@/layout/UserLayout' /* webpackChunkName: 'user-layout' */),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: { title: '个人中心' },
        component: () => import('@/views/user/ProfileView' /* webpackChunkName: 'profile' */)
      },
      {
        path: 'account',
        name: 'Account',
        meta: { title: '账户信息' },
        component: () => import('@/views/user/AccountView' /* webpackChunkName: 'account' */)
      },
      {
        path: 'asset',
        name: 'Asset',
        meta: { title: '资产和账单' },
        component: () => import('@/views/user/AssetView' /* webpackChunkName: 'asset' */)
      },
      {
        path: 'authentication',
        name: 'Authentication',
        meta: { title: '认证信息' },
        component: () => import('@/views/user/AuthenticationView' /* webpackChunkName: 'authentication' */)
      },
      {
        path: 'notification',
        name: 'Notification',
        meta: { title: '账号绑定' },
        component: () => import('@/views/user/NotificationView' /* webpackChunkName: 'notification' */)
      },
      {
        path: 'security',
        name: 'Security',
        meta: { title: '安全等级' },
        component: () => import('@/views/user/SecurityView' /* webpackChunkName: 'security' */)
      },
      {
        path: 'appearance',
        name: 'Appearance',
        meta: { title: 'MFA绑定' },
        component: () => import('@/views/user/AppearanceView' /* webpackChunkName: 'appearance' */)
      },
    ]
  },
  
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
 export const constantRouterMap = [
  // :::::::: SYSTEM ::::::::
  // {
  //   path: '/',
  //   name: 'index',
  //   component: () => import('@/layout/Layout' /* webpackChunkName: 'layout' */),
  //   redirect: '/console',
  //   children: [
  //     {
  //       path: 'console',
  //       name: 'Console',
  //       meta: { icon: 'ios-stats', title: '控制台' },
  //       component: () => import('@/views/MainView' /* webpackChunkName: 'main' */)
  //     },
  //   ]
  // },

  // :::::::: 登陆 / 注册 / 忘记密码 ::::::::
  {
    path: '/sign',
    name: 'Sign',
    meta: { title: '登陆' },
    component: () => import('@/views/user/Sign' /* webpackChunkName: 'sign' */)
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '注册' },
    component: () => import('@/views/user/Register' /* webpackChunkName: 'register' */)
  },
  {
    path: '/lost-password',
    name: 'Lost Password',
    meta: { title: '忘记密码' },
    component: () => import('@/views/user/LostPassword' /* webpackChunkName: 'lost-password' */)
  },
  
  // :::::::: NotFound ::::::::
  {
    path: '/404',
    name: 'Not Found',
    meta: { title: '404', shown: false },
    component: () => import('@/views/exception/404' /* webpackChunkName: '404' */)
  }
]