'strict'

// const columns = {};
// const files = require.context('@/config/columns/', false, /\.*\.js$/);
// files.keys().map(key => {
//   columns[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
// });

import menus from '@/config/menu.config';
import layout from '@/config/layout';

export default {
  namespaced: true,
  state: {
    ...layout,
    
    // 是否满屏
    fullscreen: false,

    // 页眉
    header: {
      // 快捷菜单按钮
      quickmenu: [
        { label: '', icon: 'fullscreen' },  // fullscreen-exit
        { label: '', icon: 'menu-unfold' }, // menu-fold
      ],

      // 头部额外按钮
      extras: []
    },
    
    // 菜单
    menu: {
      config: {
        collapsed: layout.layout.menu.collapsed,
        theme: 'dark',
        mode: 'inline',
        forceSubMenuRender: true,
        inlineCollapsed: false,
        inlineIndent: 24,
        selectable: true,
        selectedKeys: [ 'console' ],
        // defaultOpenKeys: [ 'page', 'post' ]
      },
      data: menus
    },
    
    // 页脚链接
    footer: {
      links: []
    },

    /*********************************************************
     * @description 窗体对象
     *********************************************************/
    modal: {
      show: false,
      title: undefined,
      width: 680,
      maskClosable: true,
      footer: undefined,
      bodyStyle: {},
      component: {
        name: '',
        data: {},
        options: {}
      },
      handleOk: undefined,
      afterClose: () => {}
    },
      
    /*********************************************************
    * @description 抽屉对象
    *********************************************************/
    drawer: {
      width: 680,
      title: '',
      show: false,
      maskClosable: false,
      closable: true,
      class: 'body-no-padding',
      component: {
        name: '',
        data: {},
      }
    },
  },
  mutations: {
    MENU_COLLAPSED(state, collapsed) {
      state.layout.menu.collapsed = collapsed
      state.menu.config.collapsed = collapsed
    },
    MODAL(state, params) {
      Object.assign(state.modal, params);
    }
  },
  actions: {
    menu_collapsed({ commit }, bool) {
      commit('MENU_COLLAPSED', bool);
    },
    modal({ commit }, params) {
      if (params.footer || params.handleOk) {
        params.afterClose = () => {
          commit('MODAL', { footer: undefined, handleOk: undefined });
        }
      }
      commit('MODAL', params);
    },
    modal_cancel({ commit }) {
      commit('MODAL', { show: false })
    },
    modal_ok({ state }) {
      if (typeof state.modal.handleOk === 'function') state.modal.handleOk();
    }
  }
}