module.exports = {
  brand: {
    short: 'brand.short',
    normal: 'brand.normal'
  },
  console: 'Console',
  message: {
    title: 'こんにちは、世界'
  },
  placeholder: {
    enter: 'お好きなブランドを入力してください。'
  },
  brands: {
    nike: 'Nike',
    adi: 'Adidas',
    nb: 'ニュー・百倫',
    ln: '李寧'
  },
  
  search: '捜す',
  advanced_search: '上級検索',
  actions: 'アクション',
  apply: '適用',
  manager: {
    console: 'コンソール',
    application: 'アプリ',
    message: 'メッセージ',
    setting: '設定'
  },
  opt: {
    message: 'メッセージ',
    getcode: 'コードを得る',
    setting: '設定',
    delete: '取り外し'
  },
  add: {
    application: 'アプリの追加'
  },
  views: {
    application: {
      all: '全員',
      normal: '標準'
    }
  }

}