const getters = {
  isMobile: state => state.app.isMobile,
  lang: state => state.app.lang,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.name,
  welcome: state => state.user.welcome,
  roles: state => state.user.roles,
  userInfo: state => state.user.info,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab,

  // 消息模型
  // message_data(state) {
  //   return {
  //     application_id: state.chat.application_id,
  //     conversation_id: state.chat.conversation_id,
  //     creater: {
  //       avatar: state.user.avatar,
  //       display_name: state.user.display_name,
  //       id: state.user.id,
  //     },
  //     content: '',
  //     transfer_channel: 'web',
  //     status: 'pending',
  //   }
  // }
}

export default getters