import Vue from 'vue'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)


import Dialog from '@/components/vcDialog'
Vue.use(Dialog)


import './less/backend.less'

export default Antd;