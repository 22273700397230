export default {
  brand: '蒂脉VC互動式數據雲',
  console: '控制台',
  message: {
    title: '运动牌子'
  },
  placeholder: {
    enter: '请输入你钟意嘅牌子'
  },
  brands: {
    nike: '耐尅',
    adi: '爱迪达',
    nb: '新百伦',
    ln: '李宁'
  },

  // Global
  // console: '控制台',
  search: '搜索',
  'search:': '搜索：',
  ok: '确定',
  apply: '应用',
  cancel: '取消',
  all: '全部',
  
  // Table
  account: '账号',
  realname: '姓名',
  email: '邮箱',
  role: '角色',
  registered: '注册日期',
  status: '状态',
  actions: '操作',
  
  // Roles
  superadmin: '超级管理员',
  administrator: '管理员',
  editor: '编辑',
  author: '作者',
  contributor: '撰稿人',
  subscriber: '订阅者',
  merchant: '商户',
  shopmanager: '店铺管理者',
  customer: '顾客'

}